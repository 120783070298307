
let stripe = null;
let elements = null;

function configure(config)
{
	$(document).ready(function() {

		let stripe_publishable_key = $('.handle_stripePublishableKey').val();

		if (stripe_publishable_key === undefined ||
			stripe_publishable_key.length == 0)
		{
			return;
		}

		stripe = Stripe(stripe_publishable_key);

		if ($('#stripe-payment-form')[0])
		{
			//_configurePaymentIntents(stripe);

			_configureExistingCards();
			_configureExistingAchs();
		}
		else if ($('#stripe-setup-form')[0])
		{
			_configureSetupIntents(stripe);
		}
		else if ($('#stripe-signup-form')[0])
		{
			_configureSignupPaymentIntents(stripe);
		}

		// Auto-pay checkbox
		$('#stripe_set_paymethod').click(function() {
			if ($(this).is(':checked'))
			{
				$('#stripe_autopay').fadeIn();
			}
			else
			{
				$('#stripe_autopay').hide();
			}
		});

		// If they click the button to switch payment methods, we re-init the form
		$('a.handle_payViaAchButton').click(function() {

			$('div.handle_stripeExistingCreditCardChooser').hide();

			$('div.handle_stripeExistingAchChooser').show();

			_configurePaymentIntents(stripe, 'ach');

			// Hide the form...
			$('#stripe-payment-form').hide();

			let new_ach_is_allowed = !parseInt($('input.handle_stripeDisallowNewAch').val());

			// ... and now only show if if we're actually processing ACH with Stripe
			let stripe_methods = $('input.handle_stripeIsProcessingPayMethod').map(function() { return this.value; }).get();
			if (stripe_methods.includes('ach') && new_ach_is_allowed)
			{
				$('#stripe-payment-form').show();
			}

		});

		$('a.handle_payViaCrdButton').click(function() {

			$('div.handle_stripeExistingCreditCardChooser').show();

			$('div.handle_stripeExistingAchChooser').hide();
			$('div.hande_stripeExistingAchContainer').hide();

			_configurePaymentIntents(stripe, 'crd');

			// Hide the form...
			$('#stripe-payment-form').hide();

			// ... and now only show if if we're actually processing credit cards with Stripe
			let stripe_methods = $('input.handle_stripeIsProcessingPayMethod').map(function() { return this.value; }).get();
			if (stripe_methods.includes('visa'))
			{
				$('#stripe-payment-form').show();
			}

		});

		// Default state of the form
		if (layer = window.location.hash.substr(1))
		{
			if (layer == 'creditcard')
			{
				$('div.handle_stripeExistingCreditCardChooser').show();
				$('div.handle_stripeExistingAchChooser').hide();

				_configurePaymentIntents(stripe, 'crd');

				// Hide the form...
				$('#stripe-payment-form').hide();

				// ... and now only show if if we're actually processing credit cards with Stripe
				let stripe_methods = $('input.handle_stripeIsProcessingPayMethod').map(function() { return this.value; }).get();
				if (stripe_methods.includes('visa'))
				{
					$('#stripe-payment-form').show();
				}
			}
			else if (layer == 'ach')
			{
				$('div.handle_stripeExistingCreditCardChooser').hide();
				$('div.handle_stripeExistingAchChooser').show();

				_configurePaymentIntents(stripe, 'ach');

				// Hide the form...
				$('#stripe-payment-form').hide();

				let new_ach_is_allowed = !parseInt($('input.handle_stripeDisallowNewAch').val());

				// ... and now only show if if we're actually processing ACH with Stripe
				let stripe_methods = $('input.handle_stripeIsProcessingPayMethod').map(function() { return this.value; }).get();
				if (stripe_methods.includes('ach') && new_ach_is_allowed)
				{
					$('#stripe-payment-form').show();
				}
			}
		}

	});
}

/**
 * Configure using an existing card to pay an invoice
 *
 * @private
 */
function _configureExistingCards()
{
	$('select.handle_stripeNewOrExistingCreditCard').change(function() {

		let token = $(this).val();

		// Hide cards and the container aroun dit
		$('div.handle_stripeExistingCreditCard').hide();
		$('div.handle_stripeExistingCreditCardContainer').hide();
		$('div.handle_stripeExistingAch').hide();
		$('div.handle_stripeExistingAchContainer').hide();

		// Hide Stripe form
		$('#stripe-payment-form').hide();

		if (token == 'new')
		{
			// Show the Stripe form
			$('#stripe-payment-form').show();

			// Hide the existing form
			$('div.hande_stripeExistingCreditCardContainer').hide();
		}
		else
		{
			// Show the form
			$('div.hande_stripeExistingCreditCardContainer').show();

			// Show this particular card
			$('div.handle_stripeExistingCreditCard.existing_' + token).show();
		}

	});
}

/**
 * Configure using an existing card to pay an invoice
 *
 * @private
 */
function _configureExistingAchs()
{
	$('select.handle_stripeNewOrExistingAch').change(function() {

		let token = $(this).val();

		// Hide cards and the container around it
		$('div.handle_stripeExistingAch').hide();
		$('div.handle_stripeExistingAchContainer').hide();
		$('div.handle_stripeExistingCreditCard').hide();
		$('div.handle_stripeExistingCreditCardContainer').hide();

		// Hide Stripe form
		$('#stripe-payment-form').hide();

		if (token == 'new')
		{
			// Show the Stripe form
			$('#stripe-payment-form').show();

			// Hide the existing form
			$('div.hande_stripeExistingAchContainer').hide();
		}
		else
		{
			// Show the form
			$('div.hande_stripeExistingAchContainer').show();

			// Show this particular card
			$('div.handle_stripeExistingAch.existing_' + token).show();
		}

	});
}

/**
 * Configure Stripe Elements to store a payment method (e.g. a credit card)
 *
 * @param stripe
 * @private
 */
function _configureSetupIntents(stripe)
{
	// Listen for the form submit
	document
		.querySelector('#stripe-setup-form')
		.addEventListener("submit", _handleSetupIntentSubmit);

	// Set up the payment intent
	let url_stripeSetupIntent = $('.handle_stripeSetupIntentUrl').val();

	let data = {
		gateway: $('.handle_stripeGatewayToken').val(),
		tenant: $('.handle_stripeTenantToken').val()
	};

	$.post(url_stripeSetupIntent, data, function( result ) {

		elements = stripe.elements(result);

		const paymentElementOptions = {
			layout: "tabs",
		};

		const paymentElement = elements.create("payment", paymentElementOptions);
		paymentElement.mount("#payment-element");

	});
}

function _handleSetupIntentSubmit(e)
{
	e.preventDefault();

	let stripe_publishable_key = $('.handle_stripePublishableKey').val();

	if (stripe_publishable_key === undefined ||
		stripe_publishable_key.length == 0)
	{
		return;
	}

	setLoading(true);

	let url_stripeConfirmSetup = $('.handle_stripeConfirmSetupUrl').val();

	stripe.confirmSetup({
		elements,
		confirmParams: {
			// Make sure to change this to your payment completion page
			return_url: url_stripeConfirmSetup
		},
	}).then(function(result) {

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		if (result.error &&
			( result.error.type === "card_error" || result.error.type === "validation_error" )) {
			showMessage(result.error.message);
		} else {
			showMessage("An unexpected error occurred.");
		}

		setLoading(false);

	});
}

/**
 * Configure Stripe Elements to accept a payment
 *
 * @param stripe
 * @private
 */
function _configurePaymentIntents(stripe, paymethod)
{
	// This sits and listens in the background
	//checkStatus();

	// Listen for the form submit
	document
		.querySelector('#stripe-payment-form')
		.addEventListener("submit", _handlePaymentIntentSubmit);

	// Set up the payment intent
	let url_stripePaymentIntent = $('.handle_stripePaymentIntentUrl').val();

	let pi_amount = parseFloat($('input[name=payment\\\[amount\\\]]').val());
	let pi_procfee_base = 0.0;
	let pi_procfee_percent = 0.0;

	if ($('input.handle_stripeProcFeeBase')[0])
	{
		pi_procfee_base = parseFloat($('input.handle_stripeProcFeeBase').val());
	}

	if ($('input.handle_stripeProcFeePercent')[0])
	{
		pi_procfee_percent = parseFloat($('input.handle_stripeProcFeePercent').val());
	}

	let data = {
		paymethod: paymethod,
		gateway: $('.handle_stripeGatewayToken').val(),
		invoice: $('.handle_stripeInvoiceToken').val(),
		tenant: $('.handle_stripeTenantToken').val(),
		amount: pi_amount,
		procfee_base: pi_procfee_base,
		procfee_percent: pi_procfee_percent
	};

	$.post(url_stripePaymentIntent, data, function( result ) {

		if (result.hasOwnProperty('errmsg') &&
			result.hasOwnProperty('success') &&
			!result.success)
		{
			// An error occurred!
			$('#payment-element').html('<div class="alert alert-danger"><h4>Sorry, this payment method is unavailable</h4><p>' + result.errmsg + '</p></div>').show();
			return;
		}

		elements = stripe.elements(result);

		const paymentElementOptions = {
			layout: "tabs",
		};

		const paymentElement = elements.create("payment", paymentElementOptions);
		paymentElement.mount("#payment-element");

	});
}

/**
 * Handle the Stripe form submit for paying for an invoice
 *
 * @param e
 * @private
 */
function _handlePaymentIntentSubmit(e)
{
	e.preventDefault();

	let stripe_publishable_key = $('.handle_stripePublishableKey').val();

	if (stripe_publishable_key === undefined ||
		stripe_publishable_key.length == 0)
	{
		return;
	}

	setLoading(true);

	let url_stripeConfirmPayment = $('.handle_stripeConfirmPaymentUrl').val();

	stripe.confirmPayment({
		elements,
		confirmParams: {
			// Make sure to change this to your payment completion page
			return_url: url_stripeConfirmPayment,

			// DO NOT DO THIS! If you do this, it makes _Stripe_ send a payment receipt, instead of ChargeOver
			//receipt_email: $('.handle_stripeReceiptEmail').val(),
		},
	}).then(function(result) {

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		if (result.error &&
			( result.error.type === "card_error" || result.error.type === "validation_error" )) {
			showMessage(result.error.message);
		} else {
			showMessage("An unexpected error occurred.");
		}

		setLoading(false);

	});
}

/*
// Fetches the payment intent status after payment submission
async function checkStatus() {
	const clientSecret = new URLSearchParams(window.location.search).get(
		"payment_intent_client_secret"
	);

	if (!clientSecret) {
		return;
	}

	const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

	switch (paymentIntent.status) {
		case "succeeded":
			showMessage("Payment succeeded!");
			break;
		case "processing":
			showMessage("Your payment is processing.");
			break;
		case "requires_payment_method":
			showMessage("Your payment was not successful, please try again.");
			break;
		default:
			showMessage("Something went wrong.");
			break;
	}
}
 */

/**
 * Configure Stripe Elements to accept a payment _for a new sign-up_ (hosted sign-up forms)
 *
 * @param stripe
 * @private
 */
function _configureSignupPaymentIntents(stripe)
{
	// This sits and listens in the background
	//checkStatus();

	// Listen for the form submit
	document
		.querySelector('#signupform')
		.addEventListener("submit", _handleSignupPaymentIntentSubmit);

	// Set up the payment intent
	let url_stripeSignupPaymentIntent = $('input.handle_stripeSignupPaymentIntentUrl').val();

	let pi_amount = parseFloat($('input.handle_stripeAmount').val());

	let data = {
		gateway: $('input.handle_stripeGatewayToken').val(),
		//invoice: $('.handle_stripeInvoiceToken').val(),
		//tenant: $('.handle_stripeTenantToken').val(),

		name: 'Keith Palmer',
		company: '',
		email: '',
		phone: '',
		currency: $('input.handle_stripeCurrency').val(),

		amount: pi_amount
	};

	$.post(url_stripeSignupPaymentIntent, data, function( result ) {

		elements = stripe.elements(result);

		const paymentElementOptions = {
			layout: "tabs",
		};

		const paymentElement = elements.create("payment", paymentElementOptions);
		paymentElement.mount("#payment-element");

	});
}

/**
 * Handle the Stripe form submit for a hosted sign-up submit
 *
 * @param e
 * @private
 */
function _handleSignupPaymentIntentSubmit(e)
{
	e.preventDefault();

	console.log('STOP RIGHT HERE MAN!');
	return;

	let stripe_publishable_key = $('.handle_stripePublishableKey').val();

	if (stripe_publishable_key === undefined ||
		stripe_publishable_key.length == 0)
	{
		return;
	}

	setLoading(true);

	let url_stripeSignupConfirmPayment = $('.handle_stripeSignupConfirmPaymentUrl').val();

	stripe.confirmPayment({
		elements,
		confirmParams: {
			// Make sure to change this to your payment completion page
			return_url: url_stripeSignupConfirmPayment,

			// DO NOT DO THIS! If you do this, it makes _Stripe_ send a payment receipt, instead of ChargeOver!
			//receipt_email: $('.handle_stripeReceiptEmail').val(),
		},
	}).then(function(result) {

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		if (result.error &&
			( result.error.type === "card_error" || result.error.type === "validation_error" )) {
			showMessage(result.error.message);
		} else {
			showMessage("An unexpected error occurred.");
		}

		setLoading(false);

	});
}


/**
 * Show a message to the user
 *
 * @param messageText
 */
function showMessage(messageText)
{
	const messageContainer = document.querySelector("#payment-message");

	messageContainer.classList.remove("hidden");
	messageContainer.textContent = messageText;

	setTimeout(function () {
		messageContainer.classList.add("hidden");
		messageContainer.textContent = "";
	}, 4000);
}

/**
 * Set a "loading" spinner on  the form
 *
 * @param isLoading
 */
function setLoading(isLoading)
{
	if (isLoading) {

		// Disable the button and show a spinner
		document.querySelector("#submit").disabled = true;
		document.querySelector("#spinner").classList.remove("hidden");
		document.querySelector("#button-text").classList.add("hidden");
	} else {

		document.querySelector("#submit").disabled = false;
		document.querySelector("#spinner").classList.add("hidden");
		document.querySelector("#button-text").classList.remove("hidden");
	}
}

/*
function _stripeCardInstance()
{
	if (_stripe_card == null) {
		// Custom styling can be passed to options when creating an Element.
		let style = {
			base: {
				// Add your base input styles here. For example:
				fontSize: '16px',
				color: '#32325d',
				border: '1px solid #c3c3c3'
			},
		};

		// Create an instance of the card Element.
		_stripe_card = _stripe_elements.create('card', {style: style});

		// Add an instance of the card Element into the `card-element` <div>.
		_stripe_card.mount('#stripe-card-element');

		// Handle real-time validation errors from the card Element.
		_stripe_card.addEventListener('change', function (event) {
			let displayError = document.getElementById('stripe-card-errors');
			if (event.error) {
				displayError.textContent = event.error.message;
			} else {
				displayError.textContent = '';
			}
		});
	}

	return _stripe_card;
}
*/

/**
 * Do we need to tokenize with Stripe? Returns TRUE if yes, FALSE if no.
 *
 * @returns {boolean}
 */
/*
function _stripeNeedsTokenization()
{
	return true;
}
 */

/*
function _configureStripePayment()
{
	$(document).ready(function() {

		// Set your publishable key: remember to change this to your live publishable key in production
		// See your keys here: https://dashboard.stripe.com/apikeys
		var _stripe = Stripe($('.handle_stripePublishableKey').val());
		var _stripe_elements = _stripe.elements();

		var _stripe_card = null;




		if ($('#stripe-card-element')[0])
		{
			let card = _stripeCardInstance();

			// Handle form submission.
			let forms = document.getElementsByClassName('handle_gatewayTokenizeFromClient');
			let form = forms[0];

			if (form)
			{
				form.addEventListener('submit', function (event) {

					event.preventDefault();

					if (typeof needsStripeTokenization !== "undefined" && needsStripeTokenization()) {
						// Do Stripe stuff

						_stripe.createToken(card).then(function (result) {

							if (result.error) {
								// Inform the user if there was an error.
								let errorElement = document.getElementById('stripe-card-errors');
								errorElement.textContent = result.error.message;

							} else {
								// Send the token to your server.
								stripeTokenHandler(result.token);

								// Submit the form
								form.submit();
							}
						});

					} else {
						// Don't do anything with Stripe, just submit form

						form.submit();
					}

				});
			}


		}
		*/

		/**
		 * Put the Stripe token we got back into the form, so that we can save it on the server side
		 *
		 * @param token   The Stripe token we got back from their API
		 */
		/*
		function _stripeTokenHandler(token)
		{
			// Insert the token ID into the form so it gets submitted to the server
			let forms = document.getElementsByClassName('handle_gatewayTokenizeFromClient');
			let form = forms[0];

			let input_ref = document.getElementById('cse_ref');
			input_ref.value = token.id;

			let input_gateway = document.getElementById('cse_gateway');
			input_gateway.value = 'stripe';

			let input_card_number = document.getElementById('card_number');
			input_card_number.value = 'MASKED ' + token.card.last4;

			let input_card_type = document.getElementById('creditcard_type');

			switch (token.card.brand) {
				case 'American Express':
					input_card_type.value = 'amex';
					break;
				case 'Diners Club':
					input_card_type.value = 'dine';
					break;
				case 'Discover':
					input_card_type.value = 'disc';
					break;
				case 'JCB':
					input_card_type.value = 'jcb';
					break;
				case 'MasterCard':
					input_card_type.value = 'mast';
					break;
				case 'UnionPay':
					input_card_type.value = 'unio';
					break;
				case 'Unknown':
					input_card_type.value = 'unkn'; // ChargeOverJS.CreditCard.TYPE_UNKNOWN;
					break;
				default:
				case 'Visa':
					input_card_type.value = 'visa'; //ChargeOverJS.CreditCard.TYPE_VISA;
					break;
			}
		}


	});
}
*/

// This tells Webpack to export these functions so they are
module.exports = {

	// what you want to call it externally : what the function name is internally
	configure: configure
}
